.app__gallery{
    display: flex;
    flex-direction: row;

    background :var(--color-black);

    padding: 4rem 0 4rem 6rem;
}

.app__gallery-content{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    min-width: 500px;
    padding-right: 2rem;
}

.app__gallery-content button{
    margin-top: 1rem;
}

.app__gallery-images{
    flex :1;
    display: flex;
    flex-direction: row;
    max-width: 50%;
    position: relative;
}

.app__gallery-images_container{
    display: flex;
    flex-direction: row;
    width:max-content;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.app__gallery-images_container::-webkit-scrollbar{
    display: none;
}
.app__gallery-images_card{
    position: relative;
    min-width: 301px;
    height: 447px;
    margin-right: 2rem;
}
.gallery__image-icon{
    position: absolute;
    color: white;
    font-size: 2rem;
    opacity: 0.5rem;
    transition: 0.5s ease;
    cursor: pointer;
}
.app__gallery-images_card img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: 0.5s ease;
}

.app__gallery-images_card:hover img{
    opacity: 0.35;
}

.app__gallery-images_card:hover .gallery__image-icon{
    opacity: 1;
}

.gallery__arrow-icon{
    color: var(--color-golden);
    font-size: 2rem;
    cursor: pointer;
    background-color:var(--color-black);
    border-radius: 5px;
}

.app__gallery-images_arrows{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    padding: 0 1rem;
    bottom: 5%;
}

.gallery__arrow-icon:hover{
    color: var(--color-white);
}

@media screen and(max-width:2000px) {
  .app__gallery-content{
    min-width: 1000px;
    padding-right: 4rem;
  }
  .app__gallery-images_card{
    min-width: 400px;
    height: 550px;
  }
}
@media screen and (max-width:1150px) {
    .app__gallery{
        flex-direction: column;
    }
    .app__gallery-images{
        max-width: 100%;
        margin: 5rem 0;
    }
}


@media screen and (max-width:850px) {
  .app__gallery{
    padding: 4rem 0 4rem 4rem;
  }
  .app__gallery-content{
    min-width: 100%;
  }
}
@media screen and(max-width:650px) {
  .app__gallery{
    padding: 4rem 0 4rem 2rem;
  }

  .app__gallery-content{
    min-width: 100%;
  }
  .app__gallery-images_card{
    min-width: 240px;
    height: 320px;
  }
}

