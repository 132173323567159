.app__Video{
    height: 100vh;
    position: relative;

}
.app__Video video{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.app__Video-overlay{
    position: absolute;
    inset: 0;
    background: rgb(0,0,0,0.35);
}

.app__Video-overlay_circle{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid var(--color-golden);
    cursor: pointer;
}